import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/layout"
import ArticlesComponent from "../components/Articles/articles"
import ImageTop from "../components/ImageTop/ImageTop"



export const query = graphql`
    query NewsQuery($skip: Int!, $limit: Int!) {
        strapiHomepage {
            seo {
                metaTitle
                metaDescription
            }
        }
        strapiNewsData {
            title
            image {
                localFile{
                    childImageSharp {
                        fixed(width: 1800, height: 1200) {
                            src
                        }
                    }
                }
            }
        }
        allStrapiArticle(sort:{fields: publishedAt, order: DESC} filter: { status: { eq: "published" }},limit:$limit, skip:$skip ) {
            edges {
                node {
                    strapiId
                    slug
                    title
                    publishedAt
                    image {
                        localFile{
                            childImageSharp {
                                fixed(width: 800, height: 500) {
                                    src
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

class NewsPage extends React.Component {
  render() {
    const { data } = this.props
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/news" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
      <Layout seo={data.strapiHomepage.seo}>
        <ImageTop
          image={ data.strapiNewsData.image }
          sizes='100vw'
          title={data.strapiNewsData.title}
        />
        <div className="uk-container">
          <ArticlesComponent articles={data.allStrapiArticle.edges} isFirst={isFirst} prevPage={prevPage}
                             isLast={isLast} nextPage={nextPage} numPages={numPages} currentPage={currentPage} />
        </div>
      </Layout>
    )
  }
}


export default NewsPage