import React from "react"
import Card from "../Card/card"
import { FacebookProvider, Page } from "react-facebook"
import { Link } from "gatsby"

const Articles = ({ articles, isFirst, prevPage, isLast, nextPage, numPages, currentPage }) => {
  return (
    <div className="uk-grid" data-ukgrid>
      <div className="uk-width-2-3@m">
        <h4 className="uk-heading-line uk-text-bold"><span>Najnowsze wiadomości</span></h4>

        {articles.map((article, i) => {
          return (
            <Card
              article={article}
              key={`article${article.node.slug}`}
            />
          )
        })}
        <ul className="uk-pagination uk-flex-center" data-uk-margin>
          {!isFirst && (
            <li>
              <Link to={prevPage} rel="prev">
                <span data-uk-pagination-previous />
                <span> </span>Previous
              </Link>
            </li>
          )}
          {Array.from({ length: numPages }, (_, i) => (
            <li>
              <Link key={`news${i + 1}`} to={`/news/${i === 0 ? "" : i + 1}`} activeClassName="active" className="pagination">
                {i + 1}
              </Link>
            </li>

          ))}
          {!isLast && (
            <li>
              <Link to={nextPage} rel="next">
                NEXT <span> </span>
                <span data-uk-pagination-next />
              </Link>
            </li>
          )}
        </ul>
      </div>
      <div className="uk-width-1-3@m">
        <h4 className="uk-heading-line uk-text-bold display-pc-about"><span>Facebook</span></h4>
        <div className="uk-tile uk-tile-small uk-tile-muted uk-border-rounded display-pc-about">
          <FacebookProvider appId="254736884973365">
            <Page href="https://www.facebook.com/stadnina.dworna.rogoznik" tabs="timeline" />
          </FacebookProvider>
        </div>
      </div>
    </div>
  )
}

export default Articles