import React from "react"
import Img from "gatsby-image"

const Card = ({ article }) => {
  return (
    <article className="uk-section uk-section-small uk-padding-remove-top">
      <a
        title={article.node.title}
        className="uk-link-reset link-articles"
        href={`/article/${article.node.slug}`}
      >
        <header>
          <h2 className="uk-margin-remove-adjacent uk-text-bold uk-margin-small-bottom link-articles">
            {article.node.title}
          </h2>
        </header>
        <figure>
          <Img
            fixed={article.node.image.localFile.childImageSharp.fixed}
            imgStyle={{ position: "static", height: "120%" }}
            className="blog-image"
          />
        </figure>
        <p>{article.publishedAt}</p>
      </a>
    </article>
  )
}

export default Card